import { Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { IoEyeOutline } from "react-icons/io5";
import ScrollToTop from "../ScrollToTop";
import InstagramProfile from "../InstagramProfile";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import TableLoader from "./TableLoader";

const TableView = ({
  isLoading,
  allInfluencers,
  refreshData,
  setPaginationModel,
  paginationModel,
  total,
  setFilterModel,
  setAddRows,
  preSelectedRows,
}) => {
  const [selectedRows, setSelectedRows] = useState(
    preSelectedRows?.map((influencer) => influencer._id) || []
  );
  const url = window.location.href;

  useEffect(() => {
    if (preSelectedRows) {
      const preSelectedIds = preSelectedRows.map(
        (influencer) => influencer._id
      );
      setSelectedRows(preSelectedIds);
    }
  }, [preSelectedRows]);

  const deleteByUser = useSelector((store) => store.user.userData.name);

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
    if (url.includes("campaign")) {
      const updatedSelection = selectedRows.filter((rowId) =>
        selectionModel.includes(rowId)
      );

      // Set the updated selection (add new selections)
      const finalSelection = Array.from(
        new Set([...updatedSelection, ...selectionModel])
      );

      setAddRows(finalSelection);
      // setAddRows(selectionModel);
    }
  };

  const handleDeleteSelected = async () => {
    handleDelete(selectedRows);
  };

  const notifySuccess = (message) =>
    toast.success(message || "Deleted successfully!");
  const notifyError = (error) => toast.error(error);

  const handleDelete = async (idsToDelete) => {
    try {
      await axios.delete(`${baseUrl}/influencer/deletemany`, {
        data: { ids: idsToDelete, deletedBy: deleteByUser },
        headers: {
          "Content-Type": "application/json",
          // "auth-token": token,
        },
      });
      notifySuccess("Deleted successfully!");

      await refreshData();
      setSelectedRows([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      notifyError("Error deleting files");
    }
  };

  const handleFilterChange = (newFilterModel) => {
    if (newFilterModel.items.length > 0) {
      const selectedFilter = newFilterModel.items[0];
      console.log("Selected Column:", selectedFilter.field);
      console.log("Selected Operator:", selectedFilter.operator);
      console.log("Selected Value:", selectedFilter.value);
    }
    setFilterModel(newFilterModel);
  };

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "username", headerName: "Username", width: 150 },
    {
      field: "followers",
      headerName: "Followers",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 160,
    },
    {
      field: "category",
      headerName: "Primary Category",
      width: 160,
    },
    {
      field: "mobileNum",
      headerName: "Mobile Number",
      width: 160,
    },
    {
      field: "whatsappNum",
      headerName: "Whatsapp Number",
      width: 160,
    },
    {
      field: "influencerType",
      headerName: "Influencer Type",
      width: 160,
    },
    {
      field: "quallity",
      headerName: "Quality",
      width: 160,
    },
    {
      field: "avgViews",
      headerName: "Avg Views",
      width: 160,
    },
    {
      field: "location",
      headerName: "Location",
      width: 160,
    },
    {
      field: "address",
      headerName: "Address",
      width: 160,
    },
    {
      field: "instaProfile",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => (
        <div className="actions">
          <Tooltip title="View Insta Profile" placement="top">
            <a href={params.value} target="_blank" rel="noopener noreferrer">
              <FaInstagram style={{ marginRight: 8 }} />
            </a>
          </Tooltip>
          <Tooltip title="View Full Profile" placement="top">
            <Link to={`/influencer-profile/${params?.row?.id}`}>
              <button className="btn btn_edit p-0 ms-3">
                <IoEyeOutline />
              </button>
            </Link>
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <Link to={`/edit/${params?.row?.id}`}>
              <button className="btn btn_edit p-0 ms-3">
                <MdEdit />
              </button>
            </Link>
          </Tooltip>
          <InstagramProfile
            username={params?.row?.username}
            id={params?.row?.id}
          />
        </div>
      ),
    },
  ];

  const rows = allInfluencers?.map((infl) => ({
    id: infl?._id,
    name: infl?.Name,
    username: infl?.Username,
    followers: infl?.Followers,
    email: infl?.Email,
    category: infl?.primaryCategory[0],
    mobileNum: infl?.contactNum,
    whatsappNum: infl?.whatsappNum,
    influencerType: infl?.Type,
    quallity: infl?.Quallity,
    avgViews: infl?.avgViews,
    location: infl?.Location,
    address: infl?.Address,
    instaProfile: infl?.instagramProfile ? infl?.instagramProfile : "NA",
  }));

  return (
    <>
      {isLoading ? (
        <TableLoader rows={6} columns={6} />
      ) : (
        <div
          style={{
            minHeight: "400px",
            height: "800px",
            width: "100%",
            position: "relative",
          }}
          className="mt-3"
        >
          <ScrollToTop />
          <ToastContainer />
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel,
              },
            }}
            pageSizeOptions={[10, 20, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              setPaginationModel(newModel);
            }}
            checkboxSelection
            rowCount={total}
            getRowId={(row) => row.id}
            paginationMode="server"
            onFilterModelChange={handleFilterChange}
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleSelectionChange}
            rowSelectionModel={selectedRows}
          />
          {selectedRows.length > 0 && !url.includes("campaign") && (
            <div className="selected_row_delete">
              <Tooltip title="Delete Selected Influencers" placement="top">
                <button
                  className="btn btn_delete2"
                  onClick={handleDeleteSelected}
                >
                  <MdDelete />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TableView;
