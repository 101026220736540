import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import TableView from "../influencers/TableView";
import useGetAllInfluencers from "../../utils/useGetAllInfluencers";
import TopFilters from "../influencers/TopFilters";
import LeftFilters from "../influencers/LeftFilters";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import useGetFilteredInfluencers from "../../utils/useGetFilteredInfluencers";

const AddInfluencersButton = ({
  campaignId,
  influencers,
  refreshCampData,
  setOpen,
  setMessage,
}) => {
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [location, setLocation] = useState("");
  const [followers, setFollowers] = useState("");
  const [quality, setQuality] = useState("");
  const [gender, setGender] = useState("");
  const [avgViews, setAvgViews] = useState("");
  const [primaryCat, setPrimaryCat] = useState([]);
  const [influencerType, setInfluencerType] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isGridView, setIsGridView] = useState(false);
  const [typeOfUpload, setTypeOfUpload] = useState("All");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [addRows, setAddRows] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [{ field: "", operator: "", value: "" }],
  });

  // const [selectedRows, setSelectedRows] = useState(
  //   influencers?.map((influencer) => influencer._id) || []
  // );

  const [isLoadingIds, allIds] = useGetFilteredInfluencers(
    name,
    userName,
    quality,
    primaryCat,
    gender,
    influencerType,
    location,
    followers,
    avgViews,
    fromDate,
    toDate,
    isGridView,
    typeOfUpload
  );

  const handleInfluencerTypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setInfluencerType((prev) => [...prev, value]);
    } else {
      setInfluencerType((prev) => prev.filter((type) => type !== value));
    }
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPrimaryCat((prev) => [...prev, value]);
    } else {
      setPrimaryCat((prev) => prev.filter((type) => type !== value));
    }
  };

  const [isLoading, allInfluencers, refreshData, totalCount] =
    useGetAllInfluencers(
      name,
      userName,
      quality,
      primaryCat,
      gender,
      influencerType,
      location,
      followers,
      avgViews,
      fromDate,
      toDate,
      isGridView,
      typeOfUpload,
      paginationModel,
      setPaginationModel,
      filterModel
    );

  const handleAddInfluencers = async (influencerIds) => {
    try {
      await axios.put(`${baseUrl}/${campaignId}/add-influencers`, {
        influencerIds,
      });
      refreshCampData();
      setMessage("Influencers added successfully");
      setOpen(true);
    } catch (error) {
      console.error("Error adding influencers:", error);
      setMessage("Something went wrong. Please try again later.");
      setOpen(true);
    }
  };

  const selectAllHandler = () => {
    // setAddRows(allIds);
    // setSelectedRows(allIds);
    handleAddInfluencers(allIds);
  };

  return (
    <>
      <button
        type="button"
        className="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <IoMdAdd className="icon" />
        Add Influencers
      </button>
      <div
        className="modal fade camp_modal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3
                className="modal-title modal_title fs-5"
                id="exampleModalLabel"
              >
                Select Influencers
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <TopFilters
                name={name}
                setName={setName}
                userName={userName}
                setUserName={setUserName}
                location={location}
                setLocation={setLocation}
                isModal={true}
              />
              <div className="row">
                <div className="col-md-4">
                  <LeftFilters
                    followers={followers}
                    setFollowers={setFollowers}
                    handleInfluencerTypeChange={handleInfluencerTypeChange}
                    handleCategoryChange={handleCategoryChange}
                    gender={gender}
                    setGender={setGender}
                    avgViews={avgViews}
                    setAvgViews={setAvgViews}
                    quality={quality}
                    setQuality={setQuality}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    typeOfUpload={typeOfUpload}
                    setTypeOfUpload={setTypeOfUpload}
                    isModal={true}
                  />
                </div>
                <div className="col-md-8">
                  <div className="d-flex align-items-center mt-3">
                    <p className="total_infl_text">
                      Total influencers: <span>{totalCount}</span>
                    </p>
                    <button
                      onClick={selectAllHandler}
                      className="select_all_btn"
                      data-bs-dismiss="modal"
                    >
                      Add all
                    </button>
                  </div>

                  <TableView
                    isLoading={isLoading}
                    allInfluencers={allInfluencers}
                    refreshData={refreshData}
                    setPaginationModel={setPaginationModel}
                    paginationModel={paginationModel}
                    total={totalCount}
                    setFilterModel={setFilterModel}
                    setAddRows={setAddRows}
                    preSelectedRows={influencers}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer del_modal_footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => handleAddInfluencers(addRows)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInfluencersButton;
