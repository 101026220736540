import { Tooltip } from "@mui/material";
import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { IoEyeOutline } from "react-icons/io5";

const AllCampaignTable = ({
  allCampaigns,
  selectedCampaign,
  handleSelectionChange,
  handleDeleteSelected,
  padding,
  setPaginationModel,
  paginationModel,
  total,
}) => {
  const user = useSelector((store) => store.user);
  const {
    userData: {
      permissions: { deleteCampaign, updateCampaign } = {},
      isAdmin,
    } = {},
  } = user || {};
  const columns = [
    { field: "campaignName", headerName: "Campaign Name", width: 160 },
    { field: "salesPersonName", headerName: "Sales Persone Name", width: 160 },
    {
      field: "reportingManagerName",
      headerName: "Reporting Manager Name",
      width: 170,
    },
    {
      field: "campaignType",
      headerName: "Campaign Type",
      width: 170,
    },
    {
      field: "campaignBudget",
      headerName: "Campaign Budget",
      width: 170,
    },
    {
      field: "paymentTerms",
      headerName: "Payment Terms",
      width: 170,
    },
    {
      field: "advancePaymentAmount",
      headerName: "Advance Payment Amount",
      width: 170,
    },
    {
      field: "advancePaymentDate",
      headerName: "Advance Payment Date",
      width: 170,
    },
    {
      field: "influencersBudget",
      headerName: "Influencers Budget",
      width: 170,
    },
    {
      field: "agencyFees",
      headerName: "Agency Fees",
      width: 170,
    },
    {
      field: "campaignStartDate",
      headerName: "Campaign Start Date",
      width: 170,
    },
    {
      field: "campaignEndDate",
      headerName: "Campaign End Date",
      width: 170,
    },
    {
      field: "campaignDescription",
      headerName: "Campaign Description",
      width: 270,
    },
    {
      field: "campaignDeliverables",
      headerName: "Campaign Deliverables",
      width: 170,
    },
    {
      field: "noOfInfluencers",
      headerName: "No of Influencers",
      width: 170,
    },
    {
      field: "nicheOfInfluencers",
      headerName: "Niche of Influencers",
      width: 170,
    },
    {
      field: "typeOfInfluencers",
      headerName: "Type of Influencers",
      width: 170,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      width: 170,
    },
    {
      field: "clientCode",
      headerName: "Client Code",
      width: 170,
    },
    {
      field: "gstDetails",
      headerName: "GST Details",
      width: 170,
    },
    {
      field: "clientAddress",
      headerName: "Client Address",
      width: 170,
    },
    {
      field: "campaignStatus",
      headerName: "Status",
      width: 170,
    },
    ...(isAdmin || updateCampaign
      ? [
          {
            field: "takeActions",
            headerName: "Actions",
            width: 130,
            renderCell: (params) => (
              <div className="actions">
                <Tooltip title="Edit" placement="top">
                  <Link to={`/campaign/edit-campaign/${params?.row?.id}`}>
                    <button className="btn btn_edit p-0">
                      <MdEdit />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip title="View Campaign" placement="top">
                  <Link to={`/campaign/campaign-details/${params?.row?.id}`}>
                    <button className="btn btn_edit p-0 ms-3">
                      <IoEyeOutline />
                    </button>
                  </Link>
                </Tooltip>
              </div>
            ),
          },
        ]
      : []),
  ];

  const rows = allCampaigns?.map((campaign) => ({
    id: campaign?._id,
    campaignName: campaign?.campaignName,
    salesPersonName: campaign?.salesPersonName,
    reportingManagerName: campaign?.reportingManagerName,
    campaignType: campaign?.campaignType,
    campaignBudget: campaign?.campaignBudget,
    paymentTerms: campaign?.paymentTerms,
    advancePaymentAmount: campaign?.advancePaymentAmount,
    advancePaymentDate: campaign?.advancePaymentDate?.slice(0, 10),
    influencersBudget: campaign?.influencersBudget,
    agencyFees: campaign?.agencyFees,
    campaignStartDate: campaign?.campaignStartDate?.slice(0, 10),
    campaignEndDate: campaign?.campaignEndDate?.slice(0, 10),
    campaignDescription: campaign?.campaignDescription,
    campaignDeliverables: campaign?.campaignDeliverables,
    noOfInfluencers: campaign?.numberOfInfluencers,
    nicheOfInfluencers: campaign?.nicheOfInfluencers,
    typeOfInfluencers: campaign?.typeOfInfluencers,
    clientName: campaign?.clientName[0]?.name,
    clientCode: campaign?.clientName[0]?.clientCode,
    gstDetails: campaign?.gstDetails,
    clientAddress: campaign?.clientAddress,
    campaignStatus: campaign?.campaignStatus,
  }));

  return (
    <div style={{ padding: padding }} className="mt-2">
      <div
        style={{
          height: 450,
          width: "100%",
          position: "relative",
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel,
            },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          checkboxSelection
          rowCount={total}
          getRowId={(row) => row.id}
          paginationMode="server"
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionChange}
        />
        {(isAdmin || deleteCampaign) && selectedCampaign.length > 0 && (
          <div className="selected_row_delete">
            <button className="btn btn_delete2" onClick={handleDeleteSelected}>
              <MdDelete />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllCampaignTable;
