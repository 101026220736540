import React from "react";
import noNotification from "../../assets/noNotifications.svg";

const NoNotifications = () => {
  return (
    <div className="no_notifications">
      <div>
        <img src={noNotification} alt="no notification" />
      </div>
      <div className="ms-3">
        <h2 className="notification_heading mb-0">No notifications yet</h2>
        <p className="mt-2">
          You’ll see notifications here when there’s activity.
        </p>
      </div>
    </div>
  );
};

export default NoNotifications;
