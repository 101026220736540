import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

function NotificationsLoader() {
  return (
    <>
      <div className="d-flex justify-content-between">
        <Box sx={{ width: 150, marginTop: "20px" }}>
          <Skeleton height={35} />
        </Box>
        <Box sx={{ width: 150, marginTop: "20px" }}>
          <Skeleton height={35} />
        </Box>
      </div>
      <Box sx={{ width: 660, marginTop: "10px" }}>
        <Skeleton height={35} />
      </Box>
      <Box sx={{ width: 660, marginTop: "10px" }}>
        <Skeleton height={35} />
      </Box>
      <Box sx={{ width: 200, marginTop: "10px" }}>
        <Skeleton height={35} />
      </Box>
      <hr />
      <div className="d-flex justify-content-between">
        <Box sx={{ width: 150, marginTop: "10px" }}>
          <Skeleton height={35} />
        </Box>
        <Box sx={{ width: 150, marginTop: "10px" }}>
          <Skeleton height={35} />
        </Box>
      </div>
      <Box sx={{ width: 660, marginTop: "10px" }}>
        <Skeleton height={35} />
      </Box>
      <Box sx={{ width: 660, marginTop: "10px" }}>
        <Skeleton height={35} />
      </Box>
      <Box sx={{ width: 200, marginTop: "10px" }}>
        <Skeleton height={35} />
      </Box>
    </>
  );
}

export default NotificationsLoader;
