import React from "react";
import { HiUsers } from "react-icons/hi2";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { MdCampaign } from "react-icons/md";
import { PiHandshakeFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useGetAllCampaigns from "../../utils/useGetAllCampaigns";
import OverviewLoader from "./OverviewLoader";

const Overview = () => {
  const influencers = useSelector((store) => store.influencers);

  const [isLoading, allCampaigns] = useGetAllCampaigns("active");

  return (
    <div className="overview">
      {influencers?.isLoading ? (
        <OverviewLoader />
      ) : (
        <Link to={"/influencers"}>
          <div className="card w-100">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="big_text">
                  {influencers?.influencersList?.total?.toLocaleString()}
                </p>
                <p className="small_text">Total Influencers</p>
              </div>
              <div
                className="users"
                style={{ backgroundColor: "var(--primary-color)" }}
              >
                <HiUsers />
              </div>
            </div>
          </div>
        </Link>
      )}
      {isLoading ? (
        <OverviewLoader />
      ) : (
        <Link>
          <div className="card w-100">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="big_text">{allCampaigns?.total}</p>
                <p className="small_text">Active Campaigns</p>
              </div>
              <div className="users" style={{ backgroundColor: "#80C4E9" }}>
                <MdCampaign />
              </div>
            </div>
          </div>
        </Link>
      )}
      <Link>
        <div className="card w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="big_text">18</p>
              <p className="small_text">Total Reach</p>
            </div>
            <div className="users" style={{ backgroundColor: "#fdd69f" }}>
              <PiHandshakeFill />
            </div>
          </div>
        </div>
      </Link>
      <Link>
        <div className="card w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="big_text">₹ 12,567</p>
              <p className="small_text">Budget Spent</p>
            </div>
            <div className="users" style={{ backgroundColor: "#604CC3" }}>
              <FaMoneyCheckDollar />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Overview;
