import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import CampGraphsTab from "./CampGraphsTab";
import AddInfluencersButton from "./AddInfluencersButton";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import SnackbarWithMessage from "./SnackbarWithMessage";
import TableLoader from "../influencers/TableLoader";
import whatsappLogo from "../../assets/WhatsApp_icon.png";
import gmailLogo from "../../assets/Gmail_Logo_512px.png";
import { FaUser } from "react-icons/fa6";

const CampaignInfluencers = ({
  influencers,
  // setPage,
  // setRowsPerPage,
  refreshData,
  isLoading,
  campaignName,
  startDate,
}) => {
  const params = useParams();
  const { id } = params;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const removeInfluencersFromCampaign = async (campaignId, influencerIds) => {
    try {
      await axios.put(`${baseUrl}/remove-influencers/${campaignId}`, {
        influencerIds,
      });
      refreshData();
      setMessage("Influencers removed successfully");
      setBackgroundColor("#4caf50");
      setOpen(true);
    } catch (error) {
      console.error("Error removing influencers:", error.response.data.message);
      setMessage("Something went wrong. Please try again later.");
      setBackgroundColor("#f44336");
      setOpen(true);
    }
  };

  // const handleSelectInfluencer = (influencerId) => {
  //   setSelectedInfluencers((prev) =>
  //     prev.includes(influencerId)
  //       ? prev.filter((id) => id !== influencerId)
  //       : [...prev, influencerId]
  //   );
  // };

  const sendInvitations = async () => {
    try {
      await axios.post(`${baseUrl}/send-whatsapp`, {
        influencers: influencers,
        campaignLink: campaignName?.toLowerCase(),
        campaignName,
        startDate,
      });
      setMessage("Invitations sent successfully");
      setBackgroundColor("#4caf50");
      setOpen(true);
      setSelectedInfluencers([]);
    } catch (error) {
      console.error("Error sending invitations:", error.response.data.message);
      setMessage("Something went wrong. Please try again later.");
      setBackgroundColor("#f44336");
      setOpen(true);
    }
  };

  const sendInviteOnMail = async () => {
    // const selectedInfluencersData = influencers.filter((influencer) =>
    //   selectedInfluencers.includes(influencer._id)
    // );

    const emailData = influencers.map((influencer) => ({
      name: influencer.Name,
      email: influencer.Email,
      campaignName: campaignName,
      startDate: startDate,
    }));

    try {
      const response = await axios.post(`${baseUrl}/send-bulk-emails`, {
        emails: emailData,
        campaignId: id,
      });
      if (response.data.success) {
        setMessage("Emails sent successfully!");
        setBackgroundColor("#4caf50");
        setOpen(true);
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      setMessage("Failed to send emails. Please try again.");
      setBackgroundColor("#f44336");
      setOpen(true);
    }
  };

  return (
    <div className="row mt-4">
      <SnackbarWithMessage
        open={open}
        setOpen={setOpen}
        message={message}
        backgroundColor={backgroundColor}
      />
      <div className="col-md-7">
        <div className="camp_influencers_main">
          <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
            <h4>Influencers</h4>
            <div className="vertical_line"></div>
            <div className="invites_box d-flex align-items-center">
              <p
                className="mb-0"
                style={{ fontWeight: "500", marginLeft: "8px" }}
              >
                Invite All
              </p>
              <Tooltip title="Invite on whatsapp" placement="top">
                <button onClick={sendInvitations} className="add_btn">
                  <img
                    src={whatsappLogo}
                    alt="whatsapp icon"
                    style={{ width: "25px" }}
                  />
                </button>
              </Tooltip>
              <Tooltip title="Invite on mail" placement="top">
                <button onClick={sendInviteOnMail} className="add_btn">
                  <img
                    src={gmailLogo}
                    alt="gmail icon"
                    style={{ width: "20px" }}
                  />
                </button>
              </Tooltip>
            </div>
            <div className="vertical_line"></div>
            <AddInfluencersButton
              campaignId={id}
              influencers={influencers}
              refreshCampData={refreshData}
              setOpen={setOpen}
              setMessage={setMessage}
            />
          </div>
          {isLoading ? (
            <TableLoader rows={6} columns={4} />
          ) : influencers?.length !== 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Followers</th>
                  <th>Quality</th>
                  <th>Mail Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {influencers?.map((influencer) => (
                  <tr key={influencer?._id}>
                    <td>{influencer?.Name}</td>
                    <td>{influencer?.Followers}</td>
                    <td>{influencer?.Quallity}</td>
                    <td>{influencer?.status}</td>
                    <td>
                      <Tooltip title="View Profile" placement="top">
                        <Link to={`/influencer-profile/${influencer?._id}`}>
                          <FaUser />
                        </Link>
                      </Tooltip>
                      <Tooltip title="Remove" placement="top">
                        <button
                          onClick={() =>
                            removeInfluencersFromCampaign(id, influencer?._id)
                          }
                          className="btn btn_edit p-0 ms-3 text-danger"
                        >
                          <RxCrossCircled style={{ marginBottom: "3px" }} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center mt-5 mb-5">No influencer found</p>
          )}
          {/* <div className="mt-3 d-flex align-items-center justify-content-between">
            <TablePagination
              component="div"
              count={totalInfluencers || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div> */}
        </div>
      </div>
      <div className="col-md-5">
        <div className="camp_influencers_main">
          <CampGraphsTab />
        </div>
      </div>
    </div>
  );
};

export default CampaignInfluencers;
