import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IoMdNotificationsOutline } from "react-icons/io";
import "./Notification.scss";
import { IoClose } from "react-icons/io5";
import TabsPanel from "./TabsPanel";

function NotificationSidebar() {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  const list = (
    <Box sx={{ width: 700, padding: "20px" }} role="presentation">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2 className="notification_heading mb-0">My Notifications</h2>
          <p className="mt-2" style={{ fontSize: "14px" }}>
            Notifications sent to this inbox can be viewed for up to 10 days.
          </p>
        </div>
        <button
          onClick={toggleDrawer(false)}
          className="close_notification_btn"
        >
          <IoClose />
        </button>
      </div>
      <TabsPanel />
    </Box>
  );

  return (
    <div>
      <div className="notification_box" onClick={toggleDrawer(true)}>
        <IoMdNotificationsOutline className="notification_icon" />
        {/* {notifications.length > 0 && <div className="notify_dot"></div>} */}
      </div>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        {list}
      </Drawer>
    </div>
  );
}

export default NotificationSidebar;
