import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AVG_VIEWS,
  GENDER,
  INFLUENCER_CATEGORY,
  INFLUENCER_TYPE,
  QUALITIES,
} from "../utils/constants";
import { baseUrl, imgBaseUrl } from "../apiConfig";
import useGetInfluencerById from "../utils/useGetInfluencerById";
import { Link, useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { addDataValidation } from "../utils/validation";
import Sidebar from "./Sidebar";
import { IoIosArrowRoundBack } from "react-icons/io";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const AddData = ({ onLogout }) => {
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [gender, setGender] = useState("");
  const [primaryCategory, setPrimaryCategory] = useState("");
  const [type, setType] = useState("");
  const [quality, setQuality] = useState("");
  const [avgViews, setAvgViews] = useState("");
  const [errors, setErrors] = useState({});
  const Name = useRef(null);
  const Username = useRef(null);
  const instagramProfile = useRef(null);
  const Followers = useRef(null);
  const Email = useRef(null);
  const contactNum = useRef(null);
  const whatsappNum = useRef(null);
  const Address = useRef(null);
  const pincode = useRef(null);
  const Location = useRef(null);
  const post = useRef(null);
  const reel = useRef(null);
  const story = useRef(null);
  const Youtube = useRef(null);
  const YoutubeName = useRef(null);
  const params = useParams();
  const id = params.id;
  const url = window.location.href;
  const navigate = useNavigate();

  const [influencer] = useGetInfluencerById(id);

  useEffect(() => {
    if (influencer.length !== 0 && url.includes("/edit/")) {
      Name.current.value = influencer.Name || "";
      Username.current.value = influencer.Username || "";
      instagramProfile.current.value = influencer.instagramProfile || "";
      Followers.current.value = influencer.Followers || "";
      Email.current.value = influencer.Email || "";
      contactNum.current.value = influencer.contactNum || "";
      whatsappNum.current.value = influencer.whatsappNum || "";
      Address.current.value = influencer.Address || "";
      pincode.current.value = influencer.pincode || "";
      Location.current.value = influencer.Location || "";
      reel.current.value = influencer?.Commercials?.reel || "";
      post.current.value = influencer?.Commercials?.post || "";
      story.current.value = influencer?.Commercials?.story || "";
      Youtube.current.value = influencer.Youtube || "";
      YoutubeName.current.value = influencer?.YoutubeName || "";
      setProfilePicUrl(`${imgBaseUrl}/${influencer?.profilePic}`);
      setProfilePicFile(influencer?.profilePic);

      setGender(influencer.Gender || "");
      setPrimaryCategory(influencer.primaryCategory || "");
      setType(influencer.Type || "");
      setQuality(influencer.Quallity || "");
      setAvgViews(influencer.avgViews || "");
    }
  }, [influencer, url]);

  const notifySuccess = () =>
    toast.success(
      url.includes("/edit/")
        ? "Updated Successfully"
        : "Data added successfully!!"
    );
  const notifyError = (err) => toast.error(err);
  const token = localStorage.getItem("token");

  const submitDataHandler = async () => {
    const formData = new FormData();

    formData.append("Name", Name.current.value);
    formData.append("Username", Username.current.value);
    formData.append("instagramProfile", instagramProfile.current.value);
    formData.append("Followers", Followers.current.value);
    formData.append("Type", type);
    formData.append("Email", Email.current.value);
    formData.append("contactNum", contactNum.current.value);
    formData.append("whatsappNum", whatsappNum.current.value);
    formData.append("Quallity", quality);
    formData.append("primaryCategory", primaryCategory);
    formData.append("Gender", gender);
    formData.append("avgViews", avgViews);
    formData.append("Address", Address.current.value);
    formData.append("pincode", pincode.current.value);
    formData.append("Location", Location.current.value);
    formData.append("Commercials[reel]", reel.current.value);
    formData.append("Commercials[post]", post.current.value);
    formData.append("Commercials[story]", story.current.value);
    formData.append("Youtube", Youtube.current.value);
    formData.append("YoutubeName", YoutubeName.current.value);
    formData.append("profilePic", profilePicFile);
    formData.append("uploadType", "manual");

    const requestOptions = {
      method: url.includes("/edit/") ? "PATCH" : "POST",
      body: formData,
      "auth-token": token,
    };
    const validationError = addDataValidation(
      Name.current.value,
      instagramProfile.current.value,
      Youtube.current.value,
      Email.current.value,
      contactNum.current.value,
      whatsappNum.current.value,
      pincode.current.value
    );

    if (validationError !== null) {
      notifyError(validationError);
      return;
    }

    try {
      const response = await fetch(
        url.includes("/edit/")
          ? `${baseUrl}/influencer/${id}`
          : `${baseUrl}/all-influencers`,
        requestOptions
      );

      const responseData = await response.json();

      if (!response.ok) {
        setErrors(responseData.errors || {});
        throw new Error(responseData.message || "Network response was not ok");
      }

      notifySuccess();
      if (!url.includes("/edit/")) {
        cancelHandler();
      }
    } catch (error) {
      console.error("Error:", error);
      notifyError(error.message);
    }
  };

  const cancelHandler = () => {
    Name.current.value = "";
    Username.current.value = "";
    instagramProfile.current.value = "";
    Followers.current.value = "";
    Email.current.value = "";
    contactNum.current.value = "";
    whatsappNum.current.value = "";
    Address.current.value = "";
    pincode.current.value = "";
    Location.current.value = "";
    reel.current.value = "";
    post.current.value = "";
    story.current.value = "";
    Youtube.current.value = "";
    YoutubeName.current.value = "";
    setProfilePicFile(null);
    setGender("");
    setPrimaryCategory("");
    setType("");
    setQuality("");
    setAvgViews("");
    setErrors({});
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicFile(file);
      setProfilePicUrl(URL.createObjectURL(file));
    }
  };

  const triggerFileInput = () => {
    document.getElementById("hidden-file-input").click();
  };

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <div className="wrapper">
      <Sidebar onLogout={onLogout} />
      <ScrollToTop />
      <div
        className="main-panel"
        style={{ backgroundColor: "rgb(244, 248, 251)" }}
      >
        <ToastContainer theme="colored" position="bottom-right" />
        <div className="dashboard_main">
          <div className="dashboard_header">
            <div className="go_back" onClick={backHandler}>
              <IoIosArrowRoundBack className="icon" />
            </div>
            <h4>Add Influencer</h4>
          </div>
          <div className="add_data_main mt-3">
            <div className="form_box">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        ref={Name}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name*"
                        required
                      />
                      <label htmlFor="floatingInput">Enter Name*</label>
                      {errors.Name && (
                        <span className="error">{errors.Name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        ref={instagramProfile}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Instagram Profile Link*"
                        required
                      />
                      <label htmlFor="floatingInput">
                        Instagram Profile Link*
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        ref={Username}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Instagram Username*"
                        required
                      />
                      <label htmlFor="floatingInput">Instagram Username*</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={Followers}
                        type="number"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Followers*"
                        required
                      />
                      <label htmlFor="floatingInput">No. of Followers*</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={Youtube}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Youtube Channel Link"
                      />
                      <label htmlFor="floatingInput">
                        Youtube Channel Link
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={YoutubeName}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Youtube Channel Name"
                      />
                      <label htmlFor="floatingInput">
                        Youtube Channel Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <FormControl
                      required
                      sx={{ minWidth: 120 }}
                      className="w-100"
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Gender
                      </InputLabel>
                      <Select
                        value={gender}
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        label="Gender*"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        {GENDER.slice(1).map((item) => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4 mt-5">
                    <FormControl
                      required
                      sx={{ minWidth: 120 }}
                      className="w-100"
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Primary Category
                      </InputLabel>
                      <Select
                        value={primaryCategory}
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        label="Primary Category*"
                        onChange={(e) => setPrimaryCategory(e.target.value)}
                      >
                        {INFLUENCER_CATEGORY.slice(1).map((item) => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={Email}
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                      />
                      <label htmlFor="floatingInput">Enter Email</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={contactNum}
                        type="number"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Mob"
                      />
                      <label htmlFor="floatingInput">Enter Mobile Number</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={whatsappNum}
                        type="number"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Mob"
                      />
                      <label htmlFor="floatingInput">
                        Enter Whatsapp Number
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <FormControl
                      required
                      sx={{ minWidth: 120 }}
                      className="w-100"
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Influencer Type
                      </InputLabel>
                      <Select
                        value={type}
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        label="Influencer Type*"
                        onChange={(e) => setType(e.target.value)}
                      >
                        {INFLUENCER_TYPE.map((item) => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>Required</FormHelperText> */}
                    </FormControl>
                  </div>
                  <div className="col-md-4 mt-5">
                    <FormControl
                      required
                      sx={{ minWidth: 120 }}
                      className="w-100"
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Quality
                      </InputLabel>
                      <Select
                        value={quality}
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        label="Quality*"
                        onChange={(e) => setQuality(e.target.value)}
                      >
                        {QUALITIES.slice(1).map((item) => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>Required</FormHelperText> */}
                    </FormControl>
                  </div>
                  <div className="col-md-4 mt-5">
                    <FormControl
                      required
                      sx={{ minWidth: 120 }}
                      className="w-100"
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Avg Views
                      </InputLabel>
                      <Select
                        value={avgViews}
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        label="Avg Views*"
                        onChange={(e) => setAvgViews(e.target.value)}
                      >
                        {AVG_VIEWS.slice(1).map((item) => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={Location}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Location"
                      />
                      <label htmlFor="floatingInput">Location (city)</label>
                    </div>
                  </div>
                  <div className="col-md-8 mt-5">
                    <div className="form-floating">
                      <input
                        ref={Address}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Address"
                      />
                      <label htmlFor="floatingInput">Address</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="form-floating">
                      <input
                        ref={pincode}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Pincode"
                      />
                      <label htmlFor="floatingInput">Pincode</label>
                    </div>
                  </div>
                  <p className="mt-5 text-bold">Commercials (INR)</p>
                  <div className="col-md-4 mt-2">
                    <div className="form-floating">
                      <input
                        ref={story}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Commercials"
                      />
                      <label htmlFor="floatingInput">Quote for one story</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <div className="form-floating">
                      <input
                        ref={reel}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Commercials"
                      />
                      <label htmlFor="floatingInput">Quote for one reel</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <div className="form-floating">
                      <input
                        ref={post}
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Commercials"
                      />
                      <label htmlFor="floatingInput">
                        Quote for one static post
                      </label>
                    </div>
                  </div>
                  <p className="mt-5 text-bold">Upload profile picture</p>
                  <div className="col-md-3">
                    <button class="button-4 mt-3" onClick={triggerFileInput}>
                      Select Image
                    </button>
                  </div>
                  {profilePicUrl && (
                    <div className="col-md-4 mt-3">
                      <img
                        src={profilePicUrl}
                        alt="profile pic"
                        className="uploaded_profile_pic"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    className="form-control"
                    id="hidden-file-input"
                    onChange={handleProfilePicChange}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="mt-5 btn_box_add">
                  <button className="btn btn_cancel" onClick={cancelHandler}>
                    Cancel
                  </button>
                  <button
                    className="btn btn_send"
                    type="submit"
                    onClick={submitDataHandler}
                  >
                    {url.includes("/edit/") ? "Update" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddData;
