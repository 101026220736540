import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { baseUrl, imgBaseUrl } from "../../apiConfig";
import axios from "axios";
import NoNotifications from "./NoNotifications";
import NotificationsLoader from "./NotificationsLoader";

const socket = io(imgBaseUrl);

function TabsPanel() {
  const [notifications, setNotifications] = useState([]);
  const [isRead, setIsRead] = useState(false);
  const [isUnRead, setIsUnRead] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotifications = async (readStatus) => {
    try {
      const response = await axios.get(`${baseUrl}/notifications`, {
        params: { isRead: readStatus },
      });
      setNotifications(response?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleChange = (read) => {
    if (read) {
      setIsRead(true);
      setIsUnRead(false);
      fetchNotifications(true);
    } else {
      setIsRead(false);
      setIsUnRead(true);
      fetchNotifications(false);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      await axios.post(`${baseUrl}/notification/mark-as-read`, {
        notificationId,
      });
      setNotifications((prev) =>
        prev.filter((notification) => notification._id !== notificationId)
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const timeAgo = (timestamp) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(timestamp)) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours}h ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  };

  useEffect(() => {
    fetchNotifications(false);
    socket.on("newNotification", (notification) => {
      setNotifications((prev) => {
        const updatedNotifications = [notification, ...prev];
        return updatedNotifications?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      });
    });
    return () => {
      socket.off("newNotification");
    };
  }, []);

  return (
    <>
      <div className="mt-5 tabs_btns">
        <button
          className={isUnRead ? "tab_btn tab_btn_active" : "tab_btn"}
          onClick={() => handleChange(false)}
        >
          Unread
        </button>
        <button
          className={isRead ? "tab_btn tab_btn_active" : "tab_btn"}
          onClick={() => handleChange(true)}
        >
          Read
        </button>
      </div>
      <div>
        <div className="notifications">
          {isLoading ? (
            <NotificationsLoader />
          ) : notifications?.length !== 0 ? (
            notifications?.map((notification) => (
              <div key={notification?._id} className="noti_box">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="notification_title">Campaign Application</h3>
                  <p className="light_text">
                    {timeAgo(notification?.createdAt)}
                  </p>
                </div>
                <p>{notification?.message}</p>
                {isUnRead && (
                  <button onClick={() => markAsRead(notification?._id)}>
                    Mark as read
                  </button>
                )}
                {isRead && (
                  <>
                    <button>Approve</button>
                    <button>Reject</button>
                  </>
                )}
              </div>
            ))
          ) : (
            <NoNotifications />
          )}
        </div>
      </div>
    </>
  );
}
export default TabsPanel;
