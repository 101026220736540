import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";
import { toast } from "react-toastify";

const useGetCampaignById = (id, page, rowsPerPage) => {
  const [campaignData, setCampaignData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = window.location.href;
  const token = localStorage.getItem("token");

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        // `${baseUrl}/campaign/${id}?page=${page + 1}&limit=${rowsPerPage}`,
        `${baseUrl}/campaign/${id}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setCampaignData(res?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error || "Internal Server Error");
    }
  };

  useEffect(() => {
    if (url.includes("/edit-campaign/") || url.includes("campaign-details")) {
      getData();
    }
  }, [id, url, page, rowsPerPage]);

  const refreshData = async () => {
    await getData(true);
  };

  return [campaignData, refreshData, isLoading];
};

export default useGetCampaignById;
