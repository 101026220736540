import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CAMPAIGN_STATUS,
  INFLUENCER_CATEGORY,
  INFLUENCER_TYPE,
} from "../../utils/constants";
import ScrollToTop from "../ScrollToTop";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl, imgBaseUrl } from "../../apiConfig";
import useGetAllCampaigns from "../../utils/useGetAllCampaigns";
import useGetCampaignById from "../../utils/useGetCampaignById";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import uploadImg from "../../assets/upload_img_logo.jpg";

const CreateCampaign = () => {
  const [clients, setClients] = useState([]);
  const [campaignLogoFile, setCampaignLogoFile] = useState(null);
  const [campaignLogoUrl, setCampaignLogoUrl] = useState(null);
  const [advancePaymentDate, setAdvancePaymentDate] = useState(null);
  const [campaignStartDate, setCampaignStartDate] = useState(null);
  const [campaignEndDate, setCampaignEndDate] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientNameWithCode, setClientNameWithCode] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const params = useParams();
  const { id } = params;
  const [campaignData, refreshData] = useGetCampaignById(id, 0, 10);
  const [campaignFormData, setCampaignFormData] = useState({
    campaignName: "",
    salesPersonName: "",
    reportingManagerName: "",
    campaignType: "",
    campaignBudget: "",
    paymentTerms: "",
    advancePaymentAmount: "",
    influencersBudget: "",
    agencyFees: "",
    campaignDescription: "",
    campaignDeliverables: "",
    numberOfInfluencers: "",
    nicheOfInfluencers: "",
    typeOfInfluencers: "",
    campaignStatus: "",
    gstDetails: "",
    clientAddress: "",
  });

  const user = useSelector((store) => store.user);
  const { userData: { permissions: { createCampaign } = {}, isAdmin } = {} } =
    user || {};

  useEffect(() => {
    if (id && id !== "undefined" && campaignData?.campaign) {
      setCampaignFormData({
        campaignName: campaignData?.campaign.campaignName || "",
        salesPersonName: campaignData?.campaign.salesPersonName || "",
        reportingManagerName: campaignData?.campaign.reportingManagerName || "",
        campaignType: campaignData?.campaign.campaignType || "",
        campaignBudget: campaignData?.campaign.campaignBudget || "",
        paymentTerms: campaignData?.campaign.paymentTerms || "",
        advancePaymentAmount: campaignData?.campaign.advancePaymentAmount || "",
        influencersBudget: campaignData?.campaign.influencersBudget || "",
        agencyFees: campaignData?.campaign.agencyFees || "",
        campaignDescription: campaignData?.campaign.campaignDescription || "",
        campaignDeliverables: campaignData?.campaign.campaignDeliverables || "",
        numberOfInfluencers: campaignData?.campaign.numberOfInfluencers || "",
        nicheOfInfluencers: campaignData?.campaign.nicheOfInfluencers || "",
        typeOfInfluencers: campaignData?.campaign.typeOfInfluencers || "",
        campaignStatus: campaignData?.campaign.campaignStatus || "",
        gstDetails: campaignData?.campaign.gstDetails || "",
        clientAddress: campaignData?.campaign.clientAddress || "",
      });
      setAdvancePaymentDate(
        campaignData?.campaign?.advancePaymentDate
          ? dayjs(campaignData?.campaign.advancePaymentDate)
          : null
      );
      setCampaignStartDate(
        campaignData?.campaign?.campaignStartDate
          ? dayjs(campaignData?.campaign.campaignStartDate)
          : null
      );
      setCampaignEndDate(
        campaignData?.campaign?.campaignEndDate
          ? dayjs(campaignData?.campaign.campaignEndDate)
          : null
      );
      setDefaultValue(
        campaignData?.campaign?.clientName?.length > 0
          ? {
              label: `${campaignData?.campaign.clientName[0].name}, ${campaignData?.campaign.clientName[0].clientCode}`,
              id: "someID",
            }
          : null
      );
      setCampaignLogoUrl(
        campaignData?.campaign?.campaignLogo !== null &&
          `${imgBaseUrl}/${campaignData?.campaign?.campaignLogo}`
      );
    } else {
      setCampaignFormData({
        campaignName: "",
        salesPersonName: "",
        reportingManagerName: "",
        campaignType: "",
        campaignBudget: "",
        paymentTerms: "",
        advancePaymentAmount: "",
        influencersBudget: "",
        agencyFees: "",
        campaignDescription: "",
        campaignDeliverables: "",
        numberOfInfluencers: "",
        nicheOfInfluencers: "",
        typeOfInfluencers: "",
        campaignStatus: "",
        gstDetails: "",
        clientAddress: "",
      });
      setAdvancePaymentDate(null);
      setCampaignStartDate(null);
      setCampaignEndDate(null);
      setDefaultValue(null);
      setCampaignLogoFile(null);
    }
  }, [campaignData?.campaign, id]);

  const [isLoading, allCampaigns] = useGetAllCampaigns();

  const getUniqueClients = (campaigns) => {
    const clientMap = new Map();

    campaigns.forEach((campaign) => {
      if (
        campaign.clientName &&
        Array.isArray(campaign.clientName) &&
        campaign.clientName.length > 0
      ) {
        const client = campaign.clientName[0];
        if (client.clientCode) {
          clientMap.set(client.clientCode, client.name);
        }
      }
    });

    return Array.from(clientMap, ([clientCode, name]) => ({
      clientCode,
      name,
    }));
  };

  const formatClients = (clients) => {
    return clients.map((client) => {
      return { label: `${client.name}, ${client.clientCode}` };
    });
  };

  useEffect(() => {
    if (allCampaigns && allCampaigns?.campaigns?.length > 0) {
      const uniqueClients = getUniqueClients(allCampaigns?.campaigns);
      const formattedClients = formatClients(uniqueClients);
      setClients(formattedClients);
    }
  }, [allCampaigns, isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignFormData({
      ...campaignFormData,
      [name]: value,
    });
  };

  const handleClientChange = (event, newValue) => {
    if (newValue) {
      setClientNameWithCode(newValue.label);
      const clientName = newValue?.label?.split(",");
      if (clientName) {
        setClientName(clientName[0]);
      }
    } else {
      setClientNameWithCode(null);
      setClientName("");
    }
  };

  const handleClientNewValue = (event, newInputValue) => {
    setClientName(newInputValue);
    setClientNameWithCode(newInputValue);
  };

  const generateClientCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };
  const successToastify = () => toast.success("Campaign created successfully");
  const errorToastify = (err) => toast.error(err);

  const createCampaignHandler = async () => {
    let clientCode = "";
    const existingClient = clients.find((client) => {
      return client.label === clientNameWithCode;
    });

    if (existingClient) {
      clientCode = existingClient.label.split(",")[1].trim();
    } else {
      clientCode = generateClientCode();
    }
    try {
      // const campaignData = {
      //   ...formData,
      //   clientName: [
      //     { name: clientName?.split(",")[0], clientCode: clientCode },
      //   ],
      //   advancePaymentDate: advancePaymentDate
      //     ? advancePaymentDate.toISOString()
      //     : null,
      //   campaignStartDate: campaignStartDate
      //     ? campaignStartDate.toISOString()
      //     : null,
      //   campaignEndDate: campaignEndDate ? campaignEndDate.toISOString() : null,
      //   campaignLogo: campaignLogoFile,
      // };
      const formData = new FormData();

      formData.append("campaignName", campaignFormData.campaignName);
      formData.append("salesPersonName", campaignFormData.salesPersonName);
      formData.append(
        "reportingManagerName",
        campaignFormData.reportingManagerName
      );
      formData.append("campaignType", campaignFormData.campaignType);
      formData.append("campaignBudget", campaignFormData.campaignBudget);
      formData.append("paymentTerms", campaignFormData.paymentTerms);
      formData.append(
        "advancePaymentAmount",
        campaignFormData.advancePaymentAmount
      );
      formData.append("influencersBudget", campaignFormData.influencersBudget);
      formData.append("agencyFees", campaignFormData.agencyFees);
      formData.append(
        "campaignDescription",
        campaignFormData.campaignDescription
      );
      formData.append(
        "campaignDeliverables",
        campaignFormData.campaignDeliverables
      );
      formData.append(
        "numberOfInfluencers",
        campaignFormData.numberOfInfluencers
      );
      formData.append(
        "nicheOfInfluencers",
        campaignFormData.nicheOfInfluencers
      );
      formData.append("typeOfInfluencers", campaignFormData.typeOfInfluencers);
      formData.append("campaignStatus", campaignFormData.campaignStatus);
      formData.append("gstDetails", campaignFormData.gstDetails);
      formData.append("clientAddress", campaignFormData.clientAddress);
      formData.append(
        "clientName",
        JSON.stringify([{ name: clientName?.split(",")[0], clientCode }])
      );

      if (advancePaymentDate) {
        formData.append("advancePaymentDate", advancePaymentDate.toISOString());
      }
      if (campaignStartDate) {
        formData.append("campaignStartDate", campaignStartDate.toISOString());
      }
      if (campaignEndDate) {
        formData.append("campaignEndDate", campaignEndDate.toISOString());
      }
      if (campaignLogoFile) {
        formData.append("campaignLogo", campaignLogoFile);
      }
      const requestOptions = {
        method: id ? "PATCH" : "POST",
        body: formData,
      };

      const response = await fetch(
        id ? `${baseUrl}/campaign/${id}` : `${baseUrl}/create-campaign`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to create campaign.");
      }

      await response.json();
      successToastify();
    } catch (error) {
      console.log(error);
      errorToastify(error.message);
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCampaignLogoFile(file);
      setCampaignLogoUrl(URL.createObjectURL(file));
    }
  };

  const triggerFileInput = () => {
    document.getElementById("hidden-file-input").click();
  };

  return (
    <>
      {(createCampaign || isAdmin) && (
        <div className="setting_body">
          <ScrollToTop />
          <ToastContainer />
          <div className="body">
            <h6>Create Campaign</h6>
            <form
              onSubmit={(e) => e.preventDefault()}
              className="create_campaign_form"
            >
              <div className="row">
                <div className="mt-3 col-md-4">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.campaignName || ""}
                    label="Campaign Name"
                    name="campaignName"
                    id="outlined-size-small"
                    required
                  />
                </div>
                <div className="mt-3 col-md-4">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.salesPersonName}
                    label="Sales Person Name"
                    name="salesPersonName"
                    id="outlined-size-small"
                    required
                  />
                </div>
                <div className="mt-3 col-md-4">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.reportingManagerName}
                    label="Reporting Manager Name"
                    name="reportingManagerName"
                    id="outlined-size-small"
                    required
                  />
                </div>
                <div className="mt-4 col-md-4">
                  <FormControl sx={{ m: 0, minWidth: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Campaign Type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={campaignFormData.campaignType}
                      name="campaignType"
                      onChange={handleChange}
                      label="Campaign Type"
                      required
                    >
                      <MenuItem value="barter">Barter</MenuItem>
                      <MenuItem value="paid">Paid</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="mt-4 col-md-4">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.campaignBudget}
                    name="campaignBudget"
                    label="Campaign Budget"
                    id="outlined-size-small"
                    required
                  />
                </div>
                <div className="mt-4 col-md-4">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.paymentTerms}
                    name="paymentTerms"
                    label="Payment Terms"
                    id="outlined-size-small"
                    required
                  />
                </div>
                <div className="mt-5 col-md-6">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.advancePaymentAmount}
                    name="advancePaymentAmount"
                    label="Advance Payment Amount (%)"
                    id="outlined-size-small"
                    required
                    className="w-100"
                  />
                </div>
                <div className="mt-4 col-md-6">
                  <label className="camp_lebel">Advance Payment Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={advancePaymentDate}
                      onChange={(newValue) => setAdvancePaymentDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          onChange={handleChange}
                          style={{ padding: "8px 0 8px 14px" }}
                          {...params}
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="mt-4 col-md-6">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.influencersBudget}
                    name="influencersBudget"
                    label="Influencers Budget"
                    id="outlined-size-small"
                    required
                    className="w-100"
                  />
                </div>
                <div className="mt-4 col-md-6">
                  <TextField
                    onChange={handleChange}
                    value={campaignFormData.agencyFees}
                    name="agencyFees"
                    label="Agency Fees"
                    id="outlined-size-small"
                    required
                    className="w-100"
                  />
                </div>
                <div className="mt-4 col-md-6">
                  <label className="camp_lebel">Campaign Start Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={campaignStartDate}
                      onChange={(newValue) => setCampaignStartDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          onChange={handleChange}
                          {...params}
                          style={{ padding: "8px 0 8px 14px !important" }}
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="mt-4 col-md-6">
                  <label className="camp_lebel">
                    Expected Campaign End Date
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={campaignEndDate}
                      onChange={(newValue) => setCampaignEndDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          onChange={handleChange}
                          {...params}
                          style={{ padding: "8px 0 8px 14px" }}
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="mt-4 col-md-12">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label camp_lebel"
                  >
                    Campaign Description
                  </label>
                  <textarea
                    value={campaignFormData.campaignDescription}
                    name="campaignDescription"
                    onChange={handleChange}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <div className="col-md-12">
                      <TextField
                        onChange={handleChange}
                        value={campaignFormData.campaignDeliverables}
                        name="campaignDeliverables"
                        label="Campaign Deliverables"
                        id="outlined-size-small"
                        required
                        className="w-100"
                      />
                    </div>
                    <div className="mt-4 col-md-12">
                      <TextField
                        onChange={handleChange}
                        value={campaignFormData.numberOfInfluencers}
                        name="numberOfInfluencers"
                        label="No. Of Influencers"
                        id="outlined-size-small"
                        type="number"
                        required
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="d-flex align-items-center h-100 upload_camp_logo">
                      <div className="col-md-8">
                        <p className="text-bold">Upload Campaign Logo</p>
                        <button
                          className="button-4 mt-3"
                          onClick={triggerFileInput}
                        >
                          Select Image
                        </button>
                      </div>
                      <div className="col-md-4">
                        <img
                          src={campaignLogoUrl ? campaignLogoUrl : uploadImg}
                          alt="profile pic"
                          className="uploaded_profile_pic"
                        />
                      </div>
                    </div>
                    <input
                      type="file"
                      className="form-control"
                      id="hidden-file-input"
                      onChange={handleProfilePicChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                <div className="mt-4 col-md-4">
                  <FormControl sx={{ m: 0, minWidth: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Niche of Influencers
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="nicheOfInfluencers"
                      value={campaignFormData.nicheOfInfluencers}
                      label="Niche of Influencers"
                      onChange={handleChange}
                      required
                    >
                      {INFLUENCER_CATEGORY.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="mt-4 col-md-4">
                  <FormControl sx={{ m: 0, minWidth: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Type of Influencers
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={campaignFormData.typeOfInfluencers}
                      name="typeOfInfluencers"
                      onChange={handleChange}
                      label="Type of Influencers "
                      required
                    >
                      {INFLUENCER_TYPE.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="mt-4 col-md-4">
                  <FormControl sx={{ m: 0, minWidth: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Campaign Status
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={campaignFormData.campaignStatus}
                      name="campaignStatus"
                      onChange={handleChange}
                      label="Campaign Status "
                      required
                    >
                      {CAMPAIGN_STATUS.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <h6 className="mt-4">Client Details</h6>
                <div className="mt-2 col-md-6">
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    value={
                      id ? defaultValue || { label: "", id: "" } : undefined
                    }
                    options={clients.map((option) => option)}
                    getOptionLabel={(option) =>
                      typeof option === "object" && option !== null
                        ? option.label || ""
                        : ""
                    }
                    renderInput={(params) => (
                      <>
                        <TextField {...params} label="Client Name" />
                      </>
                    )}
                    onChange={handleClientChange}
                    onInputChange={handleClientNewValue}
                  />
                </div>
                <div className="mt-2 col-md-6">
                  <TextField
                    onChange={handleChange}
                    name="gstDetails"
                    value={campaignFormData.gstDetails}
                    label="GST Details"
                    id="outlined-size-small"
                    required
                    className="w-100"
                  />
                </div>
                <div className="mt-4 col-md-12">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label camp_lebel"
                  >
                    Address
                  </label>
                  <textarea
                    value={campaignFormData.clientAddress}
                    onChange={handleChange}
                    name="clientAddress"
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Address"
                  ></textarea>
                </div>
              </div>
              <div className="btn_grp">
                <button
                  className="btn_save mt-4"
                  onClick={createCampaignHandler}
                >
                  {id ? "Update Campaign" : "Create Campaign"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCampaign;
