import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const useGetFilteredInfluencers = (
  name,
  userName,
  quality,
  primaryCat,
  gender,
  influencerType,
  location,
  followers,
  avgViews,
  fromDate,
  toDate,
  isGridView,
  typeOfUpload
) => {
  const [isLoadingIds, setIsLoadingIds] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [allIds, setAllIds] = useState([]);

  const getData = async () => {
    try {
      setIsLoadingIds(true);
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${baseUrl}/influencers/getFilteredIds?name=${name}&Username=${userName}&Quallity=${quality}&primaryCategory=${primaryCat}&Gender=${gender}&Type=${influencerType}&Location=${location}&Followers=${followers}&avgViews=${avgViews}&fromDate=${fromDate}&toDate=${toDate}&uploadType=${typeOfUpload}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      const data = await response.json();
      setTotalCount(data.total);
      // const ids = data?.ids?.map((id) => influencer._id);
      setAllIds(data?.ids);
    } catch (error) {
      console.error(error);
      setAllIds([]);
    } finally {
      setIsLoadingIds(false);
    }
  };

  const debouncedGetData = debounce(() => {
    getData();
  }, 300);

  useEffect(() => {
    debouncedGetData();
  }, [
    name,
    userName,
    quality,
    primaryCat,
    gender,
    influencerType,
    location,
    followers,
    avgViews,
    fromDate,
    toDate,
    isGridView,
    typeOfUpload,
  ]);

  return [isLoadingIds, allIds];
};

export default useGetFilteredInfluencers;
